import React from 'react'
import Content1 from '../components/content/Content1'


const Homepage = () => {
  return (
    <div>
      <Content1/>
    </div>
  )
}

export default Homepage
