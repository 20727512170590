// import "./Content1.css";
// // import hero_image from "../../assets/hero2.jpg";
// import hero1_image from "../../assets/hero1.jpg";
// import hero2_image from "../../assets/hero2.jpg";
// import hero3_image from "../../assets/hero3.jpg";
// import hero4_image from "../../assets/hero4.jpg";

// const Content1 = () => {
//   return (
//     <div className="content1-wrapper">
//       <div className="content">
//         <div className="head_line">Awareness on your fingertips</div>
//         <div className="sub_head_line">
//           Any educational news, in any language, on any device, for all ages!
//         </div>
//         <div className="cta_line">
//           <span>Download the App Now!</span>
//         </div>
//         <div className="cta_group">
//           <div className="cta_button">Android</div>
//           <div className="cta_button">iOS</div>
//         </div>
//       </div>
//       <div className="image_container">
//         {/* <img src={hero_image} alt="Hero" className="imag_tag" /> */}

//         <div
//           id="carouselExampleIndicators"
//           class="carousel slide"
//           data-ride="carousel"
//         >
//           <ol class="carousel-indicators">
//             <li
//               data-target="#carouselExampleIndicators"
//               data-slide-to="0"
//               class="active"
//             ></li>
//             <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
//             <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
//           </ol>
//           <div class="carousel-inner">
//             <div class="carousel-item active">
//               <img class="d-block w-100" src={hero1_image} alt="First slide" />
//             </div>
//             <div class="carousel-item">
//               <img class="d-block w-100" src={hero2_image} alt="Second slide" />
//             </div>
//             <div class="carousel-item">
//               <img class="d-block w-100" src={hero3_image} alt="Third slide" />
//             </div>
//             <div class="carousel-item">
//               <img class="d-block w-100" src={hero4_image} alt="Third slide" />
//             </div>
//           </div>
//           <a
//             class="carousel-control-prev"
//             href="#carouselExampleIndicators"
//             role="button"
//             data-slide="prev"
//           >
//             <span class="carousel-control-prev-icon" aria-hidden="true"></span>
//             <span class="sr-only">Previous</span>
//           </a>
//           <a
//             class="carousel-control-next"
//             href="#carouselExampleIndicators"
//             role="button"
//             data-slide="next"
//           >
//             <span class="carousel-control-next-icon" aria-hidden="true"></span>
//             <span class="sr-only">Next</span>
//           </a>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Content1;


import "./Content1.css";
import { Carousel } from "react-bootstrap"; // Import Carousel component
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import hero1_image from "../../assets/hero1.jpg";
import hero2_image from "../../assets/hero2.jpg";
import hero3_image from "../../assets/hero3.jpg";
import hero4_image from "../../assets/hero4.jpg";

const Content1 = () => {
  return (
    <div className="content1-wrapper">
      <div className="content">
        <div className="head_line">Awareness on your fingertips</div>
        <div className="sub_head_line">
          Any educational news, in any language, on any device, for all ages!
        </div>
        <div className="cta_line">
          <span>Download the App Now!</span>
        </div>
        <div className="cta_group">
          <div className="cta_button">Android</div>
          <div className="cta_button">iOS</div>
        </div>
      </div>

      {/* Carousel Component */}
      <div className="image_container">
        <Carousel>
          <Carousel.Item>
            <div className="" style={{width:'100%', display:'flex',justifyContent:'center'}}><img className="d-block w-100" src={hero1_image} alt="First slide" /></div>
            
          </Carousel.Item>
          <Carousel.Item>
          <div className="" style={{width:'100%', display:'flex',justifyContent:'center'}}><img className="d-block w-100" src={hero2_image} alt="Second slide" /> </div>
          </Carousel.Item>
          <Carousel.Item>
          <div className="" style={{width:'100%', display:'flex',justifyContent:'center'}}>
            <img className="d-block w-100" src={hero3_image} alt="Third slide" />
            </div>
          </Carousel.Item>
          <Carousel.Item>
          <div className="" style={{width:'100%', display:'flex',justifyContent:'center'}}>
            <img className="d-block w-100" src={hero4_image} alt="Fourth slide" />
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

export default Content1;
