import React from 'react'
import "./AboutContent.css"

const AboutContent = () => {
  return (
    <div>
      <div className="about-container">
      <div className="about-header">
        <h1>About Us</h1>
        <p>
          At <strong>Companion</strong>, our mission is to empower students with the knowledge they need to make informed decisions in their educational journey. We make the process of staying updated easier by providing timely news and notifications designed for students.
        </p>
      </div>

      <div className="about-section">
        <h2>What We Do</h2>
        <p>
          We provide real-time news and notifications tailored to your educational interests. From scholarship updates to important exam dates and career guidance, [Website Name] ensures that you're always informed. 
        </p>
        <p>
          Our personalized news feed caters to your specific needs, helping you navigate through academic decisions more easily.
        </p>
      </div>

      <div className="about-section">
        <h2>Why Choose Us?</h2>
        <ul>
          <li><strong>Tailored Content:</strong> Personalized news based on your academic interests and goals.</li>
          <li><strong>Multi-Language Support:</strong> We offer news in multiple languages, ensuring accessibility for everyone.</li>
          <li><strong>Access Anywhere:</strong> Stay informed on any device, anywhere, anytime.</li>
        </ul>
      </div>

      <div className="about-section">
        <h2>Our Vision</h2>
        <p>
          We envision a world where students have access to information that helps them succeed academically and make the right choices for their future. We aim to connect students with educational opportunities through easily accessible and engaging content.
        </p>
      </div>

      <div className="about-section">
        <h2>Our Core Values</h2>
        <ul>
          <li><strong>Awareness:</strong> Information is power. We believe that being informed is the first step to success.</li>
          <li><strong>Accessibility:</strong> Every student should have equal access to educational opportunities, no matter their background.</li>
          <li><strong>Empowerment:</strong> We aim to empower students by giving them the knowledge they need to take control of their future.</li>
        </ul>
      </div>

      <div className="about-cta">
        <h2>Join Us on This Journey</h2>
        <p>
          Download our app today and start making decisions that will positively impact your future!
        </p>
      </div>
    </div>
    </div>
  )
}

export default AboutContent
